.outerContainer {
  display: block;
  margin: 10px 0;
}
.containerWithScrollbar {
  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  padding: 10px 0px;
}
.containerWithoutScrollbar {
  display: flex;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  padding: 10px 0px;
  width: 100%;
  flex-wrap: wrap;
}
.itemContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  min-width: 40px;
  height: 40px;
  padding: 5px;
  margin: 5px;
  border-radius: 50px;
  font-family: "Roboto";
}
.itemContainer.isAccessible {
  cursor: pointer;
  border-color: #00a1ff;
}
.itemContainer.isAccessible:hover {
  background: rgba(0, 161, 255, 0.2);
}
.itemContainer.isSelected {
  transform: scale(1.2);
  border-width: 2px;
}
.itemContainer.hasAnsweredCorreclty {
  border-color: #17d564;
}
.itemContainer.hasAnsweredCorreclty.isAccessible:hover {
  background: rgba(23, 213, 100, 0.2);
}
.itemContainer.hasAnsweredPartiallyCorreclty {
  border-color: #ffc400;
}
.itemContainer.hasAnsweredPartiallyCorreclty.isAccessible:hover {
  background: rgba(255, 196, 0, 0.2);
}
.itemContainer.hasAnsweredIncorreclty {
  border-color: #e51919;
}
.itemContainer.hasAnsweredIncorreclty.isAccessible:hover {
  background: rgba(229, 25, 25, 0.2);
}
.itemContainer.hasAnswered.isNotAccessible {
  border-color: #5f7a85;
}
.itemContainer.containsGradableItemsByEditor.hasAnswered {
  border-color: #b5c017;
}
.itemContainer.containsGradableItemsByEditor.hasAnswered.isAccessible:hover {
  background: rgba(181, 192, 23, 0.2);
}
.finishItem > *,
.startItem > * {
  display: inline-flex;
}
.outerContainer.isFinished .finishItem,
.outerContainer.isFinished .startItem {
  color: #00a1ff;
}
